import React, { ReactElement, ReactNode } from 'react';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'src/components/common/Button';
import {
  Row,
  Container,
  Content,
  Subtitle,
  Title,
  FormWrapper,
  Label,
  Input,
  TextArea,
  FileUpload,
  ButtonWrapper,
} from './styles';
interface ILegalContent {
  children?: ReactNode;
}

const ProductForm = ({ children }: ILegalContent): ReactElement => (
  <Row noGutters>
    <Col lg="12">
      <Subtitle>Brief Us What We Need To Know</Subtitle>
    </Col>
    <Col lg="12">
      <Container>
        <Content>
          <Title>
            Stackwalker LLC, We're always here
            <br /> to help you
          </Title>

          <FormWrapper>
            <Form.Group>
              <Label>Full Name</Label>
              <Input type="text" name="name" />
            </Form.Group>

            <Form.Group>
              <Label>Work Email Address</Label>
              <Input type="email" name="email" />
            </Form.Group>

            <Form.Group>
              <Label>Company Name</Label>
              <Input type="text" name="companyName" />
            </Form.Group>

            <Form.Group>
              <Label>Company Location</Label>
              <Input type="name" name="companyLocation" />
            </Form.Group>

            <Form.Group>
              <Label>Tell Us About Your Project </Label>
              <TextArea
                as="textarea"
                type="text"
                rows={8}
                name="message"
              />
            </Form.Group>

            <Form.Group>
              <Label>Upload Project File</Label>
              <FileUpload custom>
                <Form.File.Label>PDF, Msword, etc.</Form.File.Label>
                <Form.File.Input />
              </FileUpload>
            </Form.Group>

            <ButtonWrapper>
              <Button
                type="submit"
                paddingLeft="10rem"
                paddingRight="10rem"
                as="button"
                shadow="false"
              >
                Submit
              </Button>
            </ButtonWrapper>
          </FormWrapper>
        </Content>
      </Container>
    </Col>
  </Row>
);

export default ProductForm;
