import styled from '@emotion/styled';
import BootstrapRow from 'react-bootstrap/Row';
import BootstrapContainer from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import FormFile from 'react-bootstrap/FormFile';

export const Row = styled(BootstrapRow)`
  background-color: ${({ theme }) => theme.backgroundGray};
  padding-top: 2rem;
  padding-bottom: 5rem;
`;

export const Container = styled(BootstrapContainer)`
  padding: 0;
`;

export const Content = styled.div`
  background-color: ${({ theme }) => theme.background};
  margin: 0 6rem;
  padding: 3rem 6rem;
  border: solid 1px #dedede;
  border-radius: 7px;
  box-shadow: 0 7px 40px 0 rgba(244, 244, 244, 0.5);
  @media (max-width: 992px) {
    padding: 3rem 4rem;
    margin: 0 1rem;
  }
`;

export const Subtitle = styled.h4`
  text-align: center;
  padding: 2rem;
  color: ${({ theme }) => theme.sections.headlines.small.color};
  font-size: ${({ theme }) =>
    theme.sections.headlines.small.fontSize};
`;

export const Title = styled.h3`
  text-align: center;
  color: ${({ theme }) => theme.sections.headlines.large.color};
  font-size: ${({ theme }) =>
    theme.sections.headlines.large.fontSize};
`;

export const FormWrapper = styled(Form)`
  margin-top: 3rem;
  margin-bottom: 3rem;
`;

export const Label = styled(Form.Label)`
  margin-top: 2rem;
  font-size: ${({ theme }) => theme.fontSize.medium};
`;

export const Input = styled(Form.Control)`
  border: none;
  border-bottom: 2px solid #979797;
  border-radius: unset;
`;

export const TextArea = styled(Form.Control)`
  border: 1px solid #e9e9e9;
  background-color: #fafafa;
`;

export const FileUpload = styled(FormFile)`
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0;
  label {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
  }
  input {
    cursor: pointer;
    position: relative;
    z-index: 2;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    margin: 0;
    opacity: 0;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 3rem;
`;
