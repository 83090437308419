import React, { ReactElement } from 'react';
import Layout from 'src/components/common/Layout';
import { MetaData } from 'src/components/common/Meta';
import ProductForm from 'src/components/ProductForm';
interface IProductPage {
  location: {
    pathname: string;
  };
}
const ProductPage = ({ location }: IProductPage): ReactElement => {
  return (
    <>
      <MetaData location={location} type="website" />
      <Layout isHome={false}>
        <ProductForm />
      </Layout>
    </>
  );
};

export default ProductPage;
